<template>
  <v-container v-if="apiLoaded">

    <h1>
      <span>№ {{ tender.regnum }}</span>
      <div>
        <v-btn outlined v-if="!isInSup" @click="addToSup()">Добавить в мониторинг</v-btn>
        <v-btn @click="edit()" v-if="isInSup && userInOneOfRoles('ROLE_OFFER', 'ROLE_PARTNER', 'ROLE_ADMIN')" >Редактировать</v-btn>
      </div>
    </h1>

    <section>
      <ul class="timeline">
        <li v-if="tender.publishDateTime" :class="isPast(tender.publishDateTime) ? 'active' : ''">
          <div>
            <p>Публикация</p><span>{{ formatDateTimeWithTz(tender.publishDateTime) }}</span>
          </div>
        </li>
        <li v-if="tender.monDateTime" :class="isPast(tender.monDateTime) ? 'active' : ''">
          <div>
            <p>Мониторинг</p><span>{{ tender.monDateTime | formatDateTime }}</span>
          </div>
        </li>
        <li v-if="tender.endDateTime" :class="isPast(tender.endDateTime) ? 'active' : ''">
          <div>
            <p>Подача заявок</p><span>{{ formatDateTimeWithTz(tender.endDateTime) }}</span>
          </div>
        </li>
        <li v-if="tender.okAssess1DateTime" :class="isPast(tender.okAssess1DateTime) ? 'active' : ''">
          <div>
            <p>Оценка 1х частей</p><span>{{ tender.okAssess1DateTime | formatDate }}</span>
          </div>
        </li>
        <li v-if="tender.okFinalEndDate" :class="isPast(tender.okFinalEndDate) ? 'active' : ''">
          <div>
            <p>Подача 2х частей</p><span>{{ tender.okFinalEndDate | formatDate }}</span>
          </div>
        </li>
        <li v-if="tender.okAssess2DateTime" :class="isPast(tender.okAssess2DateTime) ? 'active' : ''">
          <div>
            <p>Оценка 2х частей</p><span>{{ tender.okAssess2DateTime | formatDate }}</span>
          </div>
        </li>
        <li v-if="tender.auctionDate" :class="isPast(tender.auctionDate) ? 'active' : ''">
          <div>
            <p>Аукцион</p><span>{{ tender.auctionDate | formatDate }}</span>
          </div>
        </li>
      </ul>

      <div class="link-tabs">
        <div>
          <router-link :to="'/tender/'+ tender.id + '/info?type=supmain/notifications'">Информация</router-link>
          <router-link :to="'/tender/'+ tender.id + '/files?type=supmain/notifications'">Документы</router-link>
        </div>
      </div>
      <router-view
          :tender="tender"
          :container-id="tender.fileStorageContainerId"
          :nav-parent="'/tender/' + tender.id + '/files?type=supmain/notifications'"
          :delete-application-func="askAndDeleteApplication"
          :reloadDataFunc="reloadData"
      ></router-view>

    </section>

  </v-container>
</template>

<script>
import api from '../../modules/api'
import {customerTimeZoneDict} from '@/modules/NSI'
import {userInOneOfRoles} from "@/modules/CommonUtils";


export default {
  name: 'TenderComponent',
  props: ['setSection'],
  data() {
    return {
      apiLoaded: false,
      tenderId: null,
      isInSup: true,
    }
  },
  methods: {
    userInOneOfRoles,

    isPast(dateStr) {
      return new Date() >= new Date(dateStr)
    },

    discount(app) {
      let price = app.appPrice
      return (100 - price / this.tender.maxPrice * 100).toFixed(0)
    },

    edit() {
      this.$router.push({name: 'TenderEdit', params: {id: this.tender.id}})
    },

    async addToSup() {
      if (this.tender.customer != null) {
        delete this.tender.customer.id
        this.tender.customer.type = 'TCST';
      }

      this.tender.customer.region = {id: this.tender.customer.region.supId}

      // eslint-disable-next-line no-unreachable
      this.tender.id = null
      let req = await api.postJson('/supmain/notifications', this.tender)
      if (req.ok) {
        this.$router.push({path: '/tender/' + req.payload.id + '/info?type=supmain/notifications'})
        this.apiLoaded = false
        this.isInSup = true
        this.loadData()
      } else
        alert('Ошибка обращения к серверу')
    },

    async loadData() {
      let urlParams = new URLSearchParams(window.location.search);
      this.notificationType = urlParams.get('type');
      this.apiURL = this.notificationType
      this.tenderId = this.$route.params.id
      if (this.tenderId == null) {
        this.apiError = true
        return
      }
      let req = await api.get(this.apiURL + '/' + this.tenderId)
      if (req.ok) {
        this.tender = req.payload
        if (this.notificationType !== 'supmain/notifications') {
          let req3 = await api.get('/supmain/notifications/findByRegnum/' + this.tender.regnum)
          if (req3.ok) {
            this.isInSup = req3.payload != null;
            if (this.isInSup) this.tender = req3.payload
          } else this.apiError = true
        }
      } else {
        this.apiError = true
      }
      if (this.tender.customer == null) this.tender.customer = {}
      this.apiLoaded = true
      console.log(this.tender)
    },

    getCustomerTimeZone() {
      if (this.tender.customer == null) return undefined;
      return customerTimeZoneDict.filter(e => e.code === this.tender.customer.customerTimeZone)?.[0]?.jsName;
    },

    formatDateTimeWithTz: function (value) {
      return value
          ? (new Date(value)).toLocaleDateString('ru-RU', {
            timeZone: this.getCustomerTimeZone(),
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric'
          })
          : '—'
    },

    async askAndDeleteApplication(id){
      if(!confirm('Вы действительно хотите удалить заявку?')) return
      await this.deleteApplication(id)
    },

    async deleteApplication(id){
      let req = await api.delete('/supmain/applications/' + id)
      console.log(req)
      if(req.ok) {
        await this.loadData()
        this.$forceUpdate()
      } else {
        alert('Что-то пошло не так')
      }
    },

    async reloadData(){
      await this.loadData()
      this.$forceUpdate()
    }
  },
  async beforeMount() {
    await this.loadData()
    if(this.tender.applications != null && this.tender.applications.length > 0){
      this.setSection('APPLICATIONS')
    } else {
      this.setSection('TENDERS')
    }
  }
}
</script>

<style lang="scss" scoped>
.application-entry {
  margin-bottom: 16px;
  border-bottom: 1px solid #E3EBFC;
  padding-bottom: 8px;

  .request-wrap {
    width: 100%;
    flex-wrap: wrap;

    .request-status {
      margin: 0 0 10px 0;
    }
  }

  .request-info {
    width: 100%;

    button {
      width: 150px;
      display: block;
      margin: 6px 0;

      &:first-child {
        margin-top: 0;
      }
    }
  }
}
</style>
